@import '../variables.module';

$tile-height: 200px;
$tile-padding-top: 20px;
$tile-padding-bottom: 40px;

.resource-tile {
  height: 200px;
  text-align: center;
  border: 2px solid rgba(223, 224, 235, 0.965);
  border-radius: 5px;
  box-shadow: 0px 10px 31px -5px rgba(0, 0, 0, 0.15);
  background-color: white;
  transition: background-color 0.5s;
}

.resource-tile.with-link:hover {
  border-color: $tile-hover-border-color;
}

.resource-tile:not(.with-link):hover {
  cursor: not-allowed;
}

.resource-tile-image {
  width: 40%;
  max-width: 50%;
  max-height: 100px;
}

.resource-tile-title {
  color: $text-color;
  font-size: 1em;
  font-weight: 500
}

.doc-link {
  left: '50%';
  transform: 'translate(-50%, 0)';
}
