
h2 {
    padding-top: 50px;
    font-family: Sora;
    font-weight: 600;
}

.btn-primary {
    font-weight: 600;
    margin-top: 20px;
    margin-right: 150px;
    margin-left: 15px;
    font-family: Sora;

}

.btn-primary:hover {
    background-color: #1435A5;
}


.form-field {
    margin-top: -7px;
}

.form-body {
    padding-left: 400px;
    padding-right: 400px;
    font-family: Sora;
    font-weight: 600;
}


