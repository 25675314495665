@import "variables.module";

body {
  // background-image: $body-background-color;
}

.content-area {
  height: 100vh;
  background-image: url("body-background.png");
  background-repeat: no-repeat;
  background-size: 105%;
  //   width: 60%;
  //   margin-left: 20%;
  //   margin-right: 20%;
  //   background-color: $content-area-background-color;
  //   box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.15),
  //     -5px 1px 10px rgba(0, 0, 0, 0.15);
}

.col {
  padding: 20px;
}

/////////////////////// Header styles ///////////////////////
.header {
  display: flex;
  justify-content: center;
  background-color: $header-background-color;
}

.header-content {
  width: 275px;
}

.header-logo {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 7px;
  // margin-bottom: 5px;
}

.header-text {
  font-size: 1.5em;
  font-family: $font-family;
  font-weight: 600;
  line-height:1.5em;
  margin-top: 5px;
}

.section-header-text {
  font-size: 1.5em;
  font-weight: 500;
}

.clear-float {
  clear: left;
}

.wrapper {
  padding: 20px;
}

/////////////////////// Footer styles ///////////////////////
// .footer {
//   z-index: -1;
//   position: absolute;
//   bottom: 0px;
//   width: 100%;
//   height: 500px;
//   background-image: url("background.png");
//   background-size: 100%;
//   background-repeat: no-repeat;
// }
