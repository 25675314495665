/* Colors */
$text-color: rgba(0, 18, 25, 1);
$text-color-light: rgba(255, 255, 255, 1);

$accessory-gray: rgba(223, 224, 235, 1);

$body-background-color: rgba(255, 255, 255, 1);
$content-area-background-color: rgba(234, 236, 241, 1);
// $header-background-color: rgba(244, 244, 247, 1);
$header-background-color: rgba(255, 255, 255, 1);
$tile-hover-border-color: rgba(255, 72, 118, 1);

/* Fonts */
$font-family: 'Inter';

/* Module Export */
:export {
  text: $text-color;
  textLight: $text-color-light;
}
